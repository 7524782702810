import { useRouter } from 'next/router';
import qs from 'qs';
import { useCallback, useState } from 'react';
import { TripFilters } from '../../components/TripSearchControls/TripSearchControls';
import { TripSearchFields } from '../../interfaces/trip';
import { cleanQueryFilters } from '../../lib/utils';

export const tripsDefaultFilters: TripFilters = {
  status: [],
  highestStatus: [],
  groups: [],
  searchText: '',
  limit: 20,
  start: 0,
  searchField: TripSearchFields.details,
  isLoggedOff: false,
};

const { start: _start, ...tripsDefaultFiltersWithoutStart } =
  tripsDefaultFilters;

const useAllTripsFilters = (initialFilters?: Partial<TripFilters>) => {
  const { query, push } = useRouter();

  // `start` was renamed to `allStart` to avoid conflicts with the `start` filter of the filtered trip list
  const { allStart: queryStart } = query as { allStart: string };

  const start = queryStart
    ? parseInt(queryStart, 10)
    : tripsDefaultFilters.start;

  const [filters, setFilters] = useState<Omit<TripFilters, 'start'>>({
    ...tripsDefaultFiltersWithoutStart,
    ...initialFilters,
  });

  const updateFilters = (newFilters: Partial<TripFilters>) => {
    setFilters({ ...filters, ...newFilters });
  };

  /** Update the `allStart` param which represents the `start` filter (renamed to avoid conflicts with the `start` filter of the filtered trip list). */
  const updateQueryStringParams = ({ allStart }: { allStart: number }) => {
    const queryParams = qs.stringify(
      cleanQueryFilters(
        { ...query, allStart },
        { defaultFilters: { allStart: tripsDefaultFilters.start } },
      ),
      { arrayFormat: 'repeat' },
    );

    // Only update the query params if they've changed
    if (queryParams !== qs.stringify(query, { arrayFormat: 'repeat' })) {
      push({ query: queryParams });
    }
  };

  const handleAllTripsFilterChange = useCallback(
    (newFilters) => {
      // Don't allow any filters to be changed except the pagination
      const { start: _start, limit: _limit, ...restFilters } = newFilters;
      if (Object.keys(restFilters).length > 0) {
        throw new Error(
          'Only pagination filters can be changed in useAllTripsFilters',
        );
      }
      const { start, ...newFiltersWithoutStart } = newFilters;
      updateFilters(newFiltersWithoutStart);
      updateQueryStringParams({ allStart: start });
    },
    [query],
  );

  return {
    allTripsFilters: { ...filters, start },
    handleAllTripsFilterChange,
  };
};

export default useAllTripsFilters;
