import React from 'react';
import {
  FirstColumn,
  SecondColumn,
  TwoColumnLayout,
} from '../components/layout-body';
import SearchableTripsList from '../components/SearchableTripsList/SearchableTripsList';
import TripFiltersFeature from '../features/TripFiltersFeature/TripFiltersFeature';

const Dashboard: React.FC = (): React.ReactElement => {
  return (
    <TwoColumnLayout protect>
      <FirstColumn sticky>
        <TripFiltersFeature hideHighestStatusFilter />
      </FirstColumn>

      <SecondColumn>
        <SearchableTripsList sourceUrl="Dashboard" />
      </SecondColumn>
    </TwoColumnLayout>
  );
};

export default Dashboard;
