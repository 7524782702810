import ServiceError from './serviceError';

export class FloatPlanCreationError extends ServiceError {
  constructor(message: string) {
    super(message, 'FLOAT_PLAN_CREATION_ERROR', 400);
  }
}

export class InvalidTripPlanId extends ServiceError {
  constructor(message: string) {
    super(message, 'INVALID_TRIP_PLAN_ID', 400);
  }
}

export class MissingQueryParamsError extends ServiceError {
  constructor(message: string) {
    super(message, 'MISSING_QUERY_PARAMS_ERROR', 400);
  }
}

/** Non-auth-related fetching of a user from Strapi failed. e.g. when deleting a user. */
export class MissingUser extends ServiceError {
  constructor(message: string) {
    super(message, 'MISSING_USER', 400);
  }
}

/**
 * Used for api auth. For when you fail to fetch a user from Strapi.
 *
 * Generally happens when the system is borked (when encountered in staging) rather than when a user is actually missing. Could point to database issues.
 * Kept as a 401
 */
export class UserRetrievalFailedError extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_RETRIEVAL_FAILED_ERROR', 401);
  }
}

export class UserExistsError extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_EXISTS_ERROR', 409);
  }
}

export class UserBlocked extends ServiceError {
  static errorCode = 'USER_BLOCKED';

  constructor(message: string) {
    super(message, UserBlocked.errorCode, 403);
  }
}

export class UserDeletionError extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_DELETION_ERROR', 500);
  }
}

/** For when you get a result that should never happen. e.g. a GraphQL response is missing the root object you're querying. */
export class InvalidResponse extends ServiceError {
  constructor(message: string) {
    super(message, 'INVALID_RESPONSE', 500);
  }
}

export class NoteCreationError extends ServiceError {
  constructor(message: string) {
    super(message, 'NOTE_CREATION_ERROR', 500);
  }
}

export class GetNotesError extends ServiceError {
  constructor(message: string) {
    super(message, 'GET_NOTES_ERROR', 500);
  }
}

/** Created for when you work around Strapi limits only to end up with a query that has over 100 items in an `id_in` which makes it fail anyway. */
export class InternalQueryLengthError extends ServiceError {
  constructor(message: string) {
    super(message, 'INTERNAL_QUERY_LENGTH_ERROR', 500);
  }
}

export class UserForbidden extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_FORBIDDEN', 403);
  }
}

export class UpdateUserGroupRolesForbidden extends ServiceError {
  static errorCode = 'UPDATE_USER_GROUP_ROLES_FORBIDDEN';
  constructor(message: string) {
    super(message, UpdateUserGroupRolesForbidden.errorCode, 403);
  }
}

export class LastKnownLocationTripEndedError extends ServiceError {
  constructor(message: string) {
    super(message, 'LAST_KNOWN_LOCATION_TRIP_ENDED_ERROR', 400);
  }
}

export class ImageUploadError extends ServiceError {
  static errorCode = 'IMAGE_UPLOAD_ERROR';
  constructor(message: string) {
    // Making it a 400 because chances are the user tried to upload an image type that isn't allowed
    super(message, ImageUploadError.errorCode, 400);
  }
}
