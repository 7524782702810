import { Box, Card, Select } from '@deckee/deck-hand';
import { FilterOption } from '../../components/FilterOption/FilterOption';
import GroupMultiSelector from '../../components/GroupMultiSelector/GroupMultiSelector';
import HighestStatusMultiSelector from '../../components/HighestStatusMultiSelector/HighestStatusMultiSelector';
import useGroups from '../../hooks/use-groups';
import { TripStatus } from '../../lib/trip';
import useTripFilters from './use-trip-filters';

interface TripFiltersFeatureProps {
  hideStatusFilter?: boolean;
  hideHighestStatusFilter?: boolean;
}

const TripFiltersFeature = ({
  hideStatusFilter,
  hideHighestStatusFilter,
}: TripFiltersFeatureProps) => {
  const { tripFilters, handleTripFiltersChange } = useTripFilters();

  const {
    status: status,
    highestStatus: highestStatus,
    groups: selectedBaseGroups,
  } = tripFilters;

  const { groups: baseGroups } = useGroups({
    types: ['base'],
    myGroups: true,
  });

  return (
    <Card>
      <Card.Heading size="large" level="2" mb="0">
        Filters
      </Card.Heading>

      <Card.Body>
        {!hideHighestStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Highest status level
            </Card.Heading>
            <HighestStatusMultiSelector
              selectedStatuses={highestStatus}
              onChange={(value) => {
                handleTripFiltersChange({ highestStatus: value });
              }}
            />
          </>
        )}

        {!hideStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Status
            </Card.Heading>
            <Select
              value=""
              onChange={(e) => {
                const selectedSet = new Set([...status, e.target.value]);
                handleTripFiltersChange({
                  // Convert selectedSet to an array
                  status: Array.from(selectedSet).filter(
                    (tripStatus) => tripStatus,
                  ),
                });
              }}
            >
              <Select.Option selected value=""></Select.Option>
              <Select.Option selected={false} value={TripStatus.SAR30Incident}>
                SAR Incident
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Overdue15}>
                Overdue
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Due}>
                Due
              </Select.Option>
            </Select>
            <Box display="flex" flexWrap="wrap" pt="2">
              {status.map((statusFilter) => {
                return (
                  <FilterOption
                    onClick={() => {
                      handleTripFiltersChange({
                        status: status.filter((b) => b !== statusFilter),
                      });
                    }}
                    color="primary"
                    key={statusFilter}
                  >
                    {statusFilter}
                  </FilterOption>
                );
              })}
            </Box>
          </>
        )}

        <Card.Heading
          mt="0 !important"
          pb="10px !important"
          pl="0 !important"
          size="compact"
          level="3"
        >
          Groups
        </Card.Heading>

        <GroupMultiSelector
          groups={baseGroups}
          selectedGroupIds={selectedBaseGroups}
          onChange={(baseGroups) => {
            handleTripFiltersChange({
              groups: baseGroups,
            });
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default TripFiltersFeature;
