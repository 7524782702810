import { Box, Select } from '@deckee/deck-hand';
import React, { ChangeEvent, Fragment } from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav<React.HTMLProps<HTMLElement>>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  column-gap: 16px;
`;

const PaginationList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: baseline;
  gap: 8px;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
  justify-content: center;

  & p {
    /* This makes it a 4px gap for ellipses and 8px gap between other page numbers */
    margin-left: -2px;
    margin-right: -2px;
  }

  & li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  & li.active button {
    color: ${({ theme }) => theme.colors.black} !important;
    background-color: ${({ theme }) => theme.colors.lightgrey} !important;
    border-color: ${({ theme }) => theme.colors.black} !important;
  }

  & li:first-child button {
    margin-left: 0;
    border-radius: 50%;
  }

  & li:last-child button {
    border-radius: 50%;
    margin-right: 0;
  }

  & button {
    text-decoration: none;
    min-width: 48px;
    text-align: center;
    box-shadow: none !important;
    border-color: ${({ theme }) => theme.colors.black} !important;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    font-size: 100%;

    margin: 0px;
    border-radius: 50%;
    position: relative;
    display: block;
    margin-left: -1px;
    line-height: 48px;
    height: 48px;
    max-height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
  }

  & button:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  & button:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
`;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
  let i = from;
  const rangeArray = [];

  while (i <= to) {
    rangeArray.push(i);
    i += step;
  }

  return rangeArray;
};

const Pagination = ({
  onPageChanged,
  totalRecords = null,
  pageLimit = 30,
  maxPagesToShow = 5,
  currentPage = 1,
}: {
  onPageChanged: (properties: {
    currentPage: number;
    totalPages: number;
    pageLimit: number;
  }) => void;
  totalRecords: number;
  pageLimit: number;
  /** Maximum pages to show. This includes the 'next' and 'previous' buttons so a value of 5 can show something like '< 1 2 3 >'. Only odd numbers work. */
  maxPagesToShow: number;
  currentPage: number;
}): React.ReactElement => {
  const totalPages = Math.ceil(totalRecords / pageLimit);

  const gotoPage = (page: number) => {
    const calculatedCurrentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage: calculatedCurrentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    onPageChanged(paginationData);
  };

  const handleClick = (page) => {
    gotoPage(page);
  };

  /** Returns a list of page numbers to be displayed. */
  const fetchPageNumbers = () => {
    if (totalPages <= maxPagesToShow) {
      return range(1, totalPages);
    }
    /** The number of pages to either side of the current page. */
    const neighboursOnOneSide = Math.floor(maxPagesToShow / 2);

    let startPage = currentPage - neighboursOnOneSide;
    let endPage = currentPage + neighboursOnOneSide;

    // Snap the 5 pages selected to not go off the edge
    if (startPage < 1) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - maxPagesToShow + 1;
    }

    let pages = range(startPage, endPage);
    // If the first page isn't in the list, override the existing leftmost page
    if (pages[0] !== 1) {
      pages[0] = 1;
    }
    // If the last page isn't in the list, override the existing rightmost page
    if (pages[pages.length - 1] !== totalPages) {
      pages[pages.length - 1] = totalPages;
    }

    return pages;
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <>
      <StyledNav aria-label="Pagination">
        <Box shrink={0} display="flex" alignItems="center" gap={2}>
          Page
          <Select
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              handleClick(event?.target?.value)
            }
            value={currentPage}
            minWidth="65px"
          >
            {range(1, totalPages).map((page) => (
              <Select.Option value={page}>{page}</Select.Option>
            ))}
          </Select>
        </Box>
        <PaginationList>
          {pages.map((page, index) => (
            <Fragment key={page}>
              {index === pages.length - 1 &&
                // If there's a gap between the last two pages, show an ellipsis
                pages[pages.length - 1] - pages[pages.length - 2] > 1 && (
                  <div>...</div>
                )}

              <li className={`${currentPage === page ? ' active' : ''}`}>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(page);
                  }}
                  aria-current={currentPage === page ? 'true' : undefined}
                >
                  {page}
                </button>
              </li>
              {/* If there's a gap between the first two pages, show an ellipsis */}
              {index === 0 && pages[1] - pages[0] > 1 && <div>...</div>}
            </Fragment>
          ))}
        </PaginationList>
      </StyledNav>
    </>
  );
};

export default Pagination;
