import { useRouter } from 'next/router';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TripFilters } from '../../components/TripSearchControls/TripSearchControls';
import { Group } from '../../interfaces/group';
import { TripSearchFields } from '../../interfaces/trip';
import { TripStatus } from '../../lib/trip';
import { cleanQueryFilters } from '../../lib/utils';
import { tripsDefaultFilters } from './use-all-trips-filters';

const useTripFilters = (initialFilters?: Partial<TripFilters>) => {
  const { query, push } = useRouter();

  const [isLoggedOff, setIsLoggedOff] = useState(
    tripsDefaultFilters.isLoggedOff,
  );

  const {
    status: queryStatus,
    highestStatus: queryHighestStatus,
    groups: queryGroups,
    searchText: querySearchText,
    limit: queryLimit,
    start: queryStart,
    searchField: querySearchField,
  } = query as {
    status: TripStatus | TripStatus[];
    highestStatus: TripFilters['highestStatus'];
    groups: Group['id'] | Group['id'][];
    searchText: string;
    limit: string;
    start: string;
    searchField: TripSearchFields;
    distanceFromLat: string;
    distanceFromLon: string;
  };

  // Translate query params to the correct types
  const status: TripStatus[] =
    typeof queryStatus === 'string'
      ? [queryStatus]
      : queryStatus || tripsDefaultFilters.status;

  const highestStatus: TripFilters['highestStatus'] =
    typeof queryHighestStatus === 'string'
      ? [queryHighestStatus]
      : queryHighestStatus || tripsDefaultFilters.highestStatus;

  const groups: Group['id'][] =
    typeof queryGroups === 'string'
      ? [queryGroups]
      : queryGroups || tripsDefaultFilters.groups;

  const searchText = querySearchText || tripsDefaultFilters.searchText;
  const limit = queryLimit
    ? parseInt(queryLimit, 10)
    : tripsDefaultFilters.limit;
  const start = queryStart
    ? parseInt(queryStart, 10)
    : tripsDefaultFilters.start;
  const searchField = querySearchField || tripsDefaultFilters.searchField;
  const distanceFrom = {
    lat: query.distanceFromLat as string,
    lon: query.distanceFromLon as string,
  };

  const tripFilters: TripFilters = useMemo(
    () => ({
      status,
      highestStatus,
      groups,
      searchText,
      limit,
      start,
      searchField,
      isLoggedOff,
      distanceFrom,
    }),
    [
      status,
      highestStatus,
      groups,
      searchText,
      limit,
      start,
      searchField,
      isLoggedOff,
      distanceFrom,
    ],
  );

  const updateQueryStringParams = (params: Partial<TripFilters>) => {
    const { distanceFrom, ...restParams } = params;
    const distanceFromCoords = {};
    if (params.distanceFrom?.lat && params.distanceFrom?.lon) {
      distanceFromCoords['distanceFromLat'] = formatCoordinate(
        params.distanceFrom.lat,
      );
      distanceFromCoords['distanceFromLon'] = formatCoordinate(
        params.distanceFrom.lon,
      );
    } else if (params.distanceFrom === null) {
      distanceFromCoords['distanceFromLat'] = undefined;
      distanceFromCoords['distanceFromLon'] = undefined;
    }

    const queryParams = qs.stringify(
      cleanQueryFilters(
        { ...query, ...restParams, ...distanceFromCoords },
        {
          defaultFilters: tripsDefaultFilters,
          excludeKeys: ['isLoggedOff'],
        },
      ),
      { arrayFormat: 'repeat' },
    );

    // Only update the query params if they've changed
    if (queryParams !== qs.stringify(query, { arrayFormat: 'repeat' })) {
      push({ query: queryParams });
    }
  };

  const handleTripFiltersChange = useCallback(
    (filters: Partial<TripFilters>) => {
      const {
        start,
        limit,
        isLoggedOff: newIsLoggedOff,
        ...restFilters
      } = filters;
      if (
        Object.keys(restFilters).length > 0 ||
        // Ignore isLoggedOff if it hasn't changed - workaround for when we keep getting it coming through
        (newIsLoggedOff !== undefined && newIsLoggedOff !== isLoggedOff)
      ) {
        // If any (non-pagination) filters are changed, reset to the first page
        filters['start'] = 0;
      }

      updateQueryStringParams(filters);

      if (filters.isLoggedOff !== undefined) {
        setIsLoggedOff(filters.isLoggedOff === true);
      }
    },
    [query],
  );

  useEffect(() => {
    if (initialFilters) {
      handleTripFiltersChange(initialFilters);
    }
  }, [initialFilters]);

  return {
    tripFilters,
    handleTripFiltersChange,
  };
};

export default useTripFilters;

/** Trim to 6 digits with no trailing zeroes */
function formatCoordinate(coordinate: string) {
  return `${parseFloat(parseFloat(coordinate).toFixed(6))}`;
}
