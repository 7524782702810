export default class ServiceError extends Error {
  code: string;
  statusCode: number = 500;

  constructor(message, code, statusCode = 500) {
    super(message); // Call the parent constructor
    this.name = this.constructor.name; // Set the error name to the class name
    this.code = code; // Add the custom code property
    this.statusCode = statusCode; // Add the custom statusCode property
    Error.captureStackTrace(this, this.constructor); // Captures stack trace
  }
}
