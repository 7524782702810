import { UserProfile, useUser } from '@auth0/nextjs-auth0';
import { useCallback } from 'react';
import useSWR from 'swr';
import { User } from '../interfaces/user';
import {
  canAdministerGroup,
  checkUserPermissions,
  transformIntoPermissionGroups,
} from '../lib/authorization/permissions';
import { UserPermissions } from '../lib/authorization/roles';
import fetcher from '../lib/fetcher';

const useLoloMember = (): {
  member: User;
  isLoading: boolean;
  error: Error;
  canAdminister: (groupId?: string) => boolean;
  check: (action: UserPermissions, data?: Record<string, unknown>) => boolean;
} => {
  const { user, isLoading: isUserLoading, error: userError } = useUser();

  // Note: we just get a minimal set of member data from the Auth0 user

  let member: User | undefined;

  try {
    member = transformAuth0User(user, isUserLoading, userError);
  } catch (e) {
    console.error('Error transforming user into member', e);
  }

  const {
    data: groupRolesData,
    isLoading: isGroupRolesLoading,
    error: groupRolesError,
  } = useSWR(member?.id ? `/api/group-roles` : null, fetcher);

  const error = userError || groupRolesError;
  const isLoading = isUserLoading || isGroupRolesLoading;

  const permissionGroups = transformIntoPermissionGroups(groupRolesData);

  const canAdminister = useCallback(
    (groupId?: string) => {
      return canAdministerGroup(member, permissionGroups, groupId);
    },
    [permissionGroups],
  );

  const check = useCallback(
    (action: UserPermissions, data?: Record<string, unknown>): boolean => {
      return checkUserPermissions({
        user: member,
        permissionGroups,
        action,
        data,
      });
    },
    [member, permissionGroups],
  );

  return {
    member: groupRolesData
      ? {
          ...member,
          permissionGroups: permissionGroups,
        }
      : undefined,
    isLoading,
    error,
    canAdminister,
    check,
  };
};

/** Transforms Auth0 user data into the structure expected for a LOLO member */
function transformAuth0User(
  user: UserProfile,
  isLoading: boolean,
  error: Error | undefined,
): User | undefined {
  if (!user || isLoading || error) {
    return undefined;
  }

  const { entityStoreMemberID, developerMode, role } = user[
    'https://search-rescue.deckee.com/app_metadata'
  ] as {
    entityStoreMemberID?: string;
    developerMode?: boolean;
    role?: string;
  };

  // if (!entityStoreMemberID) {
  //   throw new Error('Entity store member ID is missing');
  // }

  return {
    id: entityStoreMemberID,
    firstName: (user.nickname as string) || '',
    lastName: (user.family_name as string) || '',
    email: user.email,
    role,
    developerMode,

    // The auth0 user doesn't have the hidden information available
    hidden: undefined,
  };
}

export default useLoloMember;
