import { DateTime, Zone } from 'luxon';
import { Trip } from '../interfaces/trip';
import { TripWaypoint } from '../interfaces/trip-waypoint';

export const validateWaypointsForUpdateRequest = (
  points: TripWaypoint[],
  tripType: Trip['timeType'],
) => {
  points.forEach((point) => {
    // Each points ordinal should be unique
    if (!!points.find((p) => p.ordinal === point.ordinal && point !== p)) {
      throw new Error('Two points have the same ordinal');
    }
  });

  // A return trip must have at least three points
  if (tripType === 'return' && points.length < 3) {
    throw new Error('Return trips must have at least three waypoints');
  }

  // A one way trip must have at least two points
  if (tripType === 'return' && points.length < 2) {
    throw new Error('One way trips must have at least two waypoints');
  }

  // A return trips first and last point must have the same lat lon
  if (
    tripType === 'return' &&
    points[0].latLon.lat !== points[points.length - 1].latLon.lat &&
    points[0].latLon.lon !== points[points.length - 1].latLon.lon
  ) {
    throw new Error(
      'Return trips first and last point must have the same lat lon',
    );
  }

  if (
    tripType === 'return' &&
    points[0].name !== points[points.length - 1].name
  ) {
    throw new Error('Return trips must have the same start and end point');
  }
};

export function metersToFeet(meters: number): number {
  return meters * 3.2808399;
}

export function getDateTimeWithZoneConverter(timezone: Zone) {
  return (isoDateTime: string) =>
    DateTime.fromISO(isoDateTime).setZone(timezone);
}

/** Ensures the query param value is an array. `undefined` becomes `[]` */
export function asArray(value: string | string[] | undefined): string[] {
  if (Array.isArray(value)) {
    return value;
  }
  if (value) {
    return [value];
  }
  return [];
}

/** Ensures the query param is cast to a number, or an error is thrown if not possible. */
export function asNumber(value: string | string[] | undefined): number {
  if (Array.isArray(value)) {
    throw new Error('Array cannot be cast to a number');
  }
  if (value === undefined || value === null) {
    throw new Error('Nullish value cannot be cast to a number');
  }
  return parseInt(value);
}

export function getDateTimeWithZoneFormatter(timezone: Zone, format: string) {
  return (isoDateTime: string) =>
    DateTime.fromISO(isoDateTime).setZone(timezone).toFormat(format);
}

/**
 * Hack to get syntax highlighting using a GraphQL extension in VSCode (e.g. Apollo or the GraphQL Foundation one).
 * This function accepts a template literal and is just a stripped-down version of Apollo's function which accepts a tagged template.
 */
export function gql(literals: string | readonly string[]): string {
  if (typeof literals === 'string') {
    literals = [literals];
  }
  return literals[0];
}

export const HEADER_ACTIVE_NAV_INDICATOR_SIZE = '5px';

export const isProductionEnvironment = () => {
  // Note: NAMESPACE isn't set at build time, so this works best when run on the client (avoid SSR running this as it'll get the below error)
  if (!!process.env.NAMESPACE) {
    return process.env.NAMESPACE === 'production';
  }
  if (typeof window === 'undefined') {
    throw new Error(
      'NAMESPACE env var not set on the backend. Run this function on the client instead',
    );
  }
  return !window.location.hostname.includes('staging');
};

/** This is for essentially un-sanitizing strings returned from the backend. As long as it's NEVER used to `dangerouslySetInnerHTML` it's safe to use in React/Next.js. */
export const decodeHtmlEntities = (text?: string) => {
  if (!text) {
    return '';
  }
  return text
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&#96;/g, '`')
    .replace(/&#x27;/g, "'")
    .replace(/&#x2F;/g, '/')
    .replace(/&#x5C;/g, '\\');
};

export function cleanQueryFilters<T>(
  filters: Partial<T>,
  {
    defaultFilters,
    excludeKeys,
  }: { defaultFilters: Partial<T>; excludeKeys?: string[] },
) {
  const outputFilters = { ...filters };

  // No need to display default values in the URL
  for (const key in defaultFilters) {
    if (defaultFilters[key] == filters[key]) {
      delete outputFilters[key];
    }
  }

  // e.g. don't include isLoggedOff in the URL for trips as it's implied by being on the history page
  for (const key of excludeKeys ?? []) {
    delete outputFilters[key];
  }

  return outputFilters;
}
