import { Box, Heading, MessageBlock } from '@deckee/deck-hand';
import { useRouter } from 'next/router';
import useAllTripsFilters from '../../features/TripFiltersFeature/use-all-trips-filters';
import useTripFilters from '../../features/TripFiltersFeature/use-trip-filters';
import useLoloMember from '../../hooks/use-lolo-member';
import useTrips from '../../hooks/use-trips';
import { TripSearchFields } from '../../interfaces/trip';
import trackEvent from '../../lib/amplitude';
import TripList from '../organisms/trip-list';
import TripSearchControls, {
  TripFilters,
} from '../TripSearchControls/TripSearchControls';

interface SearchableTripsListProps {
  /** These filters should apply to both "Filter results" and "All results". e.g. `isLoggedOff: true` for the history page  */
  initialFilters?: Partial<TripFilters>;
  sourceUrl?: string;
}

const SearchableTripsList = ({
  sourceUrl,
  initialFilters,
}: SearchableTripsListProps) => {
  const router = useRouter();

  const {
    member,
    isLoading: isMemberLoading,
    error: memberError,
  } = useLoloMember();

  const {
    tripFilters: filteredTripFilters,
    handleTripFiltersChange: handleFilteredTripFilterChange,
  } = useTripFilters(initialFilters);

  const { allTripsFilters, handleAllTripsFilterChange } =
    useAllTripsFilters(initialFilters);

  const {
    trips: filteredTrips,
    apiResponse: filteredTripsData,
    error: filteredTripsError,
    isLoading: isLoadingFilteredTrips,
  } = useTrips(member, filteredTripFilters);

  const {
    trips: allTrips,
    apiResponse: allTripsData,
    error: allTripsError,
    isLoading: isLoadingAllTrips,
  } = useTrips(member, allTripsFilters);

  const filterActive =
    filteredTripFilters.searchText !== '' ||
    filteredTripFilters?.groups?.length > 0 ||
    filteredTripFilters?.highestStatus?.length > 0 ||
    filteredTripFilters?.status?.length > 0;

  const onPageChanged = (
    pageData: {
      currentPage: number;
      totalPages: number;
      pageLimit: number;
    },
    handleFilterChange: (newFilters: Partial<TripFilters>) => void,
  ) => {
    const { currentPage, pageLimit } = pageData;

    const start = (currentPage - 1) * pageLimit;

    handleFilterChange({
      start,
    });
  };

  const handleSelectTrip = (id: number) => {
    trackEvent('select-trip', { trip_id: id, source_url: sourceUrl || '/' });
    router.push({
      pathname: '/trips/[id]',
      query: { id, sourceUrl: sourceUrl || '/' },
    });
  };

  const isProximitySearch =
    (filteredTripFilters.searchField === TripSearchFields.proximityToLatLon ||
      filteredTripFilters.searchField === TripSearchFields.proximityToSearch) &&
    filteredTripFilters.distanceFrom;
  const showSearchResults = filterActive || isProximitySearch;

  return (
    <>
      <Box display="flex" mb="3">
        {isMemberLoading && <div>loading...</div>}
        {member && (
          <TripSearchControls
            filters={filteredTripFilters}
            onChange={handleFilteredTripFilterChange}
          />
        )}
      </Box>

      <Box display={showSearchResults ? 'block' : 'none'} mb="3">
        <Heading level={4}>Filter results</Heading>
        <TripList
          isLoading={isLoadingFilteredTrips}
          hasError={filteredTripsError}
          errorMessage={
            <MessageBlock my="2" w="100%" type="error">
              Unable to get filtered trips. Check the filters used, refresh the
              page and try again. If the issue continues, contact support.
            </MessageBlock>
          }
          filters={filteredTripFilters}
          trips={filteredTrips}
          total={filteredTripsData?.total}
          onCardClick={handleSelectTrip}
          emptyMessage={
            !isLoadingFilteredTrips &&
            !filteredTripsError && (
              <MessageBlock my="2" w="100%" type="info">
                No results found.
              </MessageBlock>
            )
          }
          onPageChanged={(pageData) =>
            onPageChanged(pageData, handleFilteredTripFilterChange)
          }
        />
      </Box>

      <Box mb="5">
        <Heading
          display={filterActive && !isProximitySearch ? 'block' : 'none'}
          level={4}
        >
          All results
        </Heading>
        <Box display={!isProximitySearch ? 'block' : 'none'} mb="3">
          <TripList
            isLoading={isLoadingAllTrips}
            hasError={allTripsError}
            errorMessage={
              <MessageBlock mb="3" my="2" w="100%" type="error">
                Unable to get trips. Refresh the page and try again. If the
                issue continues, contact support.
              </MessageBlock>
            }
            filters={allTripsFilters}
            trips={allTrips}
            total={allTripsData?.total}
            onCardClick={handleSelectTrip}
            emptyMessage={
              !isLoadingAllTrips &&
              !allTripsError && (
                <MessageBlock my="2" w="100%" type="info">
                  No trips found.
                </MessageBlock>
              )
            }
            onPageChanged={(pageData) =>
              onPageChanged(pageData, handleAllTripsFilterChange)
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default SearchableTripsList;
